import api from '@/api/projects'
import {notify} from "@/helpers/notifyHelper";

export default {
	name: 'CreateArticle',
	data () {
		return {
			valid: false,
			images: null,
			file: null,
			titleImage: null,
			mobileImages: null,
			fileTitleImage: null,
			mobileFileTitleImage: null,
			formData: {
				name: '',
				urlName: null,
				description: '',
				metaTitle: '',
				metaDescription: '',
				metaKeywords: ''
			}
		}
	},
	methods: {
		clearFile(name) {
			this[name] = null
		},
		create () {
			if (this.$refs.form.validate()) {
				if (this.images && this.file) {
					notify(this, 'error', 'You can load only file or images')
					return
				}

				if (!this.images && !this.file) {
					notify(this, 'error', 'You have to load file or images')
					return
				}

				var regExpression = /^[a-zA-Z0-9-]*$/
				if (!regExpression.test(this.formData.urlName)) {
					notify(this, 'error', 'Link may be string and - ')
					return
				}

				if (this.images && this.images.length > 6) {
					this.images = null
					notify(this, 'error', 'You have to load no more 6 head images')
					return
				}

				if (this.mobileImages && this.mobileImages.length > 6) {
					this.mobileImages = null
					notify(this, 'error', 'You have to load no more 6 mobile images')
					return
				}

				let data = new FormData()
				Object.keys(this.formData).map(key => {
					data.append(key, this.formData[key])
				})

				if (this.images) {
					for (let i = 0; i < this.images.length; i++) {
						data.append('images', this.images[i]);
					}
				}

				if (this.file) {
					data.append('file', this.file);
				}

				if (this.titleImage) {
					data.append('titleImage', this.titleImage);
				}

				if (this.mobileImages) {
					for (let i = 0; i < this.mobileImages.length; i++) {
						data.append('mobileImages', this.mobileImages[i]);
					}
				}

				if (this.fileTitleImage) {
					data.append('fileTitleImage', this.fileTitleImage);
				}

				if (this.mobileFileTitleImage) {
					data.append('mobileFileTitleImage', this.mobileFileTitleImage);
				}

				api.create(this, data)
					.then(() => {
						this.$router.push({name: 'Projects'})
					})
			}
		}
	}
}
